import React from "react"
import { Link } from "gatsby"
import shape from "../../assets/images/shape/vector-shape6.png"
import starIcon from "../../assets/images/star-icon.png"
import SoftwareDevelopment from "../../assets/images/services/software-development.png";
import WebDevelopment from "../../assets/images/services/web.png";
import Wordpress from "../../assets/images/services/wp.png"
import MobileAppIcon from "../../assets/images/services/mobile-app-icon.png"
import GraphicsIcon from "../../assets/images/services/graphics.png"
import OnlineShopping from "../../assets/images/services/online-shopping.png"

const RelatedCourses = () => {
  const services = [
    {
      serviceName: "Software Development",
      courseDes:
        "Making software programs are not an easy job, but our Software developers are very experienced and familiar with all modern tools.",
      courseImage: SoftwareDevelopment,
      path: "/software-development",
    },
    {
      serviceName: "Web Development",
      courseDes:
        "Our Flexible and Expert IT team can turn your local or international business or idea into Responsive and attractive website.",
      courseImage: WebDevelopment,
      path: "/web-development",
    },
    {
      serviceName: "WordPress",
      courseDes:
        "WordPress is a Simplest but most powerful and popular tool. You can easily manage your site, even if you don't have any IT experience.",
      courseImage: Wordpress,
      path: "/wordpress",
    },
    {
      serviceName: "Mobile App Development",
      courseDes:
        "Our Mobile App Developers are adopting modern user based methodologies, and technologies for building both IOS AND Android mobile apps.",
      courseImage: MobileAppIcon,
      path: "/mobile-app-development",
    },
    {
      serviceName: "Product & Design",
      courseDes:
        "Product designing is exactly what end-user see. Our creative graphics designers will make a suitable and fresh design ideas for your product.",
      courseImage: GraphicsIcon,
      path: "/prodect-design",
    },
    {
      serviceName: "Ecommerce",
      courseDes:
        "Our professional Market Expert will solve your problem related to Online Shopping. We will provide you solution related to Amazon and Shopify Stores.",
      courseImage: OnlineShopping,
      path: "/ecommerce",
    },
  ]
  return (
    <div className="courses-area bg-fafafb pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="star" />
            Our Services
          </span>
          <h2>We Are Offering All sort of IT Solutions Services</h2>
        </div>

        <div className="row">
          {services.map((val, i) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-services-box ">
                  <div className="icon">
                    <img
                      src={val.courseImage}
                      alt="about"
                      style={{ width: 60, height: 60 }}
                    />
                  </div>
                  <h3>{val.serviceName}</h3>
                  <p>{val.courseDes}</p>

                  <Link to={val.path} className="read-more-btn">
                    View Details <i className="flaticon-right"></i>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="vector-shape6">
        <img src={shape} alt="course" />
      </div>
    </div>
  )
}

export default RelatedCourses
